<template>
  <div class="bg-smoke rounded mb-3">
    <Details
      :left="$t('reservationAndUsageHistory.noParkingRequired')"
      :right="status"
      :divider="number > 0"
      :aClass="'font-weight-bold text-right --medium-gray col-6 pb-2'"
    />
<!--    <v-divider class="mx-4 bg-dark"/>-->
    <Details
      v-if="number > 0"
      :left="$t('reservationAndUsageHistory.numberOfCars')"
      :right="`${number}${$t('common.stand')}`"
      :aClass="'font-weight-bold --medium-gray text-right col-6'"
    />
  </div>
</template>

<script>
import Details from './index'

export default {
  name: 'Parking',
  components: {
    Details
  },
  props: {
    number: {
      String
    }
  },
  computed: {
    status () { return this.number > 0 ? this.$t('common.necessary') : this.$t('common.unnecessary') }
  }
}
</script>
