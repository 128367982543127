<template>
  <div class="my-3">
    <Details
      :left="$t('text.selectPlan.plan').toString()"
      :right="title"
      :aClass="'text-medium --c-bg'"
      :cardClass="'rounded-t'"
      :divider="true"
    />
    <Details
      v-for="(plan, planIndex, i) in bookingPlanParsed"
      v-if="typeof plan.answer.text !== 'undefined'"
      :key="planIndex"
      :left="$t('common.question', { number: planIndex }).toString()"
      :right="plan.answer.text || $t('text.selectPlan.notAnswered')"
      :aClass="'text-medium --c-bg'"
      :cardClass="i === lastAnswer ? 'rounded-b' : ''"
      :divider="i !== lastAnswer"
    />
  </div>
</template>

<script>
import Details from './index'

export default {
  name: 'BookingPlan',
  components: {
    Details
  },
  props: {
    bookingPlan: Object, // this is from FinalConfirmation
    bookingPlanAnswer: Object, // this is from the ReservationDetails
    planName: String // this is from the ReservationDetails
  },
  computed: {
    title () {
      return this.planName ?? this.$store.getters.selectedBookingPlan?.title ?? ''
    },
    lastAnswer () {
      return Object.keys(this.bookingPlanParsed).length - 1
    },
    bookingPlanParsed () {
      if (this.bookingPlan) return this.bookingPlan
      else if (this.bookingPlanAnswer) {
        return {
          1: { answer: { text: this.bookingPlanAnswer.answer1 ?? undefined } },
          2: { answer: { text: this.bookingPlanAnswer.answer2 ?? undefined } },
          3: { answer: { text: this.bookingPlanAnswer.answer3 ?? undefined } },
          4: { answer: { text: this.bookingPlanAnswer.answer4 ?? undefined } },
          5: { answer: { text: this.bookingPlanAnswer.answer5 ?? undefined } },
        }
      }
      return {}
    }
  }
}
</script>
