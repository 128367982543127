<template>
  <div class="bg-smoke rounded mb-3">
    <Details
      :left="$t('reservationAndUsageHistory.rentalListing') + (index + 1)"
      :right="rental"
      :divider="true"
      :aClass="'--medium-gray col-8 pb-2'"
    />
<!--    <v-divider class="mx-4 bg-dark"/>-->
    <Details
      :left="$t('reservationAndUsageHistory.lendingSchedule')"
      :right="schedule"
      :aClass="'--medium-gray col-8'"
    />
  </div>
</template>

<script>
import Details from './index'

export default {
  name: 'RentalList',
  components: {
    Details
  },
  props: {
    rental: {
      String
    },
    schedule: {
      String
    },
    index: {
      Number
    }
  }
}
</script>
